import axios from 'axios';
import Cookie from '@/utils/cookie.js';
import Swal from 'sweetalert2';
import SwalAxios from '@/utils/swal-axios.js';
import LoaderAxios from '@/utils/loader-axios.js';

const url = 'https://api.xanax666.xyz'; // https://api.xanax666.xyz // http://xanax.dev
const token = JSON.parse(Cookie.get('__user')).token ?? '';

// start axios handler
const request = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json'
  }
});

// request handler
request.interceptors.request.use(
  (request) => {
    LoaderAxios.start();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response handler
request.interceptors.response.use(
  (result) => {
    LoaderAxios.stop();

    let response = result;
    let data = result.data;

    if (data.isSwal) {
      response.swal = SwalAxios.then(data);
    }

    return response;
  },
  (error) => {
    LoaderAxios.stop();

    let response = error.response;
    let data = response.data;

    if (data.isSwal) {
      return SwalAxios.catch(data);
    }

    if (error.response.status == 401) {
      return Swal.fire({
        title: 'Invalid session!',
        text: 'you session has expired',
        icon: 'error'
      }).then(() => {
        Cookie.set('__user', '');
        Cookie.set('__token', '');

        window.location.reload();
      });
    }

    if (error.response.status == 500) {
      return Swal.fire({
        title: 'Server error!',
        text: 'we are unable to process your request.',
        icon: 'error'
      }).then(() => {
        window.location.reload();
      });
    }

    if (error.response.status == 422) {
      return SwalAxios.catch(error);
    }

    return Promise.reject(error);
});

export default request;
