export default {
	start() {
		let elements = this.elements();

		elements.loader.style.display = 'block';
		elements.view.style.display = 'none';
		elements.header.style.display = 'none';
		elements.footer.style.display = 'none';
	},
	stop() {
		let elements = this.elements();
		let showHideHeaderFooter = ['/auth/login', '/auth/register'].includes(
      window.location.pathname
    );

		elements.view.style.display = 'block';
    elements.loader.style.display = 'none';

    if (!showHideHeaderFooter) {
      elements.header.style.display = '';
      elements.footer.style.display = '';
    }
	},
	elements: () => {
		return {
			loader: document.getElementById('loader'),
			view: document.getElementById('view'),
			header: document.getElementsByTagName('header')[0],
			footer: document.getElementsByTagName('footer')[0],
		}
	}
};
