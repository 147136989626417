import Swal from 'sweetalert2';

export default {
  async toast(content) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    return Toast.fire(content);
  },
  async then(response) {
    return Swal.fire({
      title: response.title,
      html: response.text,
      icon: response.icon,
    });
  },
  async catch(error) {
    let response = error.response.data;
    let validation = Object.values(response.errors);
    let errors = '';

    validation.forEach((key) => {
      key.forEach((value) => {
        errors = errors + '<br>' + value;
      });
    });

    return await Swal.fire({
      title: response.message,
      html: errors,
      icon: 'error',
    });
  }
}